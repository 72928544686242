import { ResetPasswordError } from "../../../Apis/UsersApi2";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { useUsersApi2 } from "../../../apis";
import { useState } from "react";

export function useResetPassword() {
  const usersApi = useUsersApi2();
  const [resetErrorMessage, setResetErrorMessage] =
    useState<ErrorMessageType>();

  async function resetPassword(
    email: string,
    resetToken: string,
    newPassword: string,
  ): Promise<boolean> {
    try {
      await usersApi.postUsersResetPassword({
        email,
        newPassword,
        resetToken,
      });
      return true;
    } catch (error: unknown) {
      console.error(`#### error creating account`, { error });
      if (error instanceof ResetPasswordError) {
        const e = error.data;
        if (e.message.toLowerCase().includes("password")) {
          setResetErrorMessage(e.message);
        } else {
          console.error("Unknown error in resetPassword", { e });
          setResetErrorMessage(e.message);
        }
      }

      return false;
    }
  }

  return { resetPassword, resetErrorMessage };
}
